import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Layout from './pages/Layout';
import PersistLogin from './pages/PersistLogin';
import Main from './pages/Main';
import Home from './pages/Home';
import NewHome from './pages/_New_Home/Home'
import useAuth from './hooks/useAuth';
import Login from './pages/Login';
import Event from './pages/Event';
import Prediction from './pages/Prono';
import { getCookie } from './lib/cookies';
import ProfileMain from './pages/Profile/ProfileMain';
import GameRules from './pages/GameRules/GameRules';
import Ranking from './pages/Ranking/Ranking';
import Partner from './pages/Partner/Partner';
import axios from './lib/axios'
import RequireAuth from './context/RequireAuth';
import Management from './pages/Management/Management';
import Department from './pages/Partner/Department/Department';
import Invite from './pages/Invite/invite';
import { ExplanationInvite } from './pages/Explanation/Uitnodiging';
import { useTranslation } from 'react-i18next';
import { setDefaultOptions } from "date-fns";
import { nl, fr, enUS } from "date-fns/locale";
import { isInterface } from './lib/utils';
import Policy from './pages/Policy/Policy';
import ResetPassword from './pages/ResetPassword/ResetPassword';

function App() {
  const {setAuth} = useAuth()
  const { i18n } = useTranslation()

  const tryLoginOnRender = async () => {
    try {
      const {data:me} = await axios.get(`me`)
      if(me){
        setAuth({data:me, status:'authenticated'})
        //i18n.changeLanguage(me.m_sLanguage.toLowerCase())
      }
    } catch (err) {
      localStorage.removeItem('authToken')
      setAuth({data:null, status:'unauthenticated'})
      //console.log(err)
    }
  }

  useEffect(()=>{
    tryLoginOnRender()

    const l = window.sessionStorage.getItem("i18nextLng")
    if(l && (l.toLowerCase() === 'nl' || l.toLowerCase() === 'fr' || l.toLowerCase() === 'en' || l.toLowerCase() === 'de')) { i18n.changeLanguage(l.toLowerCase()) }
  }, [])

  useEffect(()=>{
    window.sessionStorage.setItem("language", i18n.language.toLowerCase());
    if(i18n.language.toLowerCase() !== 'nl' && i18n.language.toLowerCase() !== 'fr' && i18n.language.toLowerCase() !== 'en' && i18n.language.toLowerCase() !== 'de') { i18n.changeLanguage('nl') }
    switch (i18n.language) {
      case 'nl':
        setDefaultOptions({ locale: nl})
        break;
      case 'fr':
        setDefaultOptions({ locale: fr})
        break;
      case 'en':
          setDefaultOptions({ locale: enUS})
          break;
      default:
        setDefaultOptions({ locale: enUS})
        break;
    }
  }, [i18n.language])

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        
        <Route element={<Main />}>
          {["","home"].map((path, index) => 
            <Route path={path} element={process.env.NODE_ENV === 'development' ? <NewHome /> : <NewHome />} key={index}/>
          )}

          {/* Public pages */}
          <Route path="klassementen" element={<Ranking />}/>
          <Route path="wedstrijdreglement" element={<GameRules />}/>
          <Route path="cookies" element={<Policy />}/>
          <Route path="reset-password" element={<ResetPassword />}/>

          <Route path="event/:eventname/*">
            <Route path=":pronoid" element={<Prediction />}/>
            <Route path="" element={<Event />}/>
          </Route>
          
          {/* Inloggen verplicht */}
          <Route path="" element={<RequireAuth/>}>
            <Route path=":partnername/*">
              <Route path="" element={<Partner />}/>

              <Route path="team/:departmentname" element={<Department />}/>

              <Route path=":eventname/*">
                <Route path=":pronoid" element={<Prediction />}/>
                <Route path="" element={<Event />}/>
              </Route>
            </Route>

            <Route path="profile/*" element={<ProfileMain />}/>
          </Route>
        </Route>

        {/* Catch */}
        <Route path="*" element={<><p>Page not found</p></>}/>
      </Route>
    
      <Route path="management" element={<Management />}/>
      <Route path="inv/:lang/:title/:partnername/:actionid/:code" element={<Invite />} />

      <Route path="uitleg/*">
        <Route path="uitnodiging/:page" element={<ExplanationInvite />}/>
      </Route>

      <Route path="unauthorized" element={<>Unauthorized</>}/>
      <Route path="hello" element={<>Hello stranger!</>}/>
    </Routes>
  );
}

export default App;

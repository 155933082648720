import React, { useState, useEffect, useRef } from 'react'
import { AlertTriangle, Bell, Building2, CheckIcon, GlobeIcon, Grip, Link2, LogOut, Menu, Settings, User, User2, Users, X } from 'lucide-react'
import logo from '../assets/logo/logo_white.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginPopup } from '../redux/actions'
import { RootState } from '../redux'
import useAuth from '../hooks/useAuth'
import { Link, useLocation } from 'react-router-dom'
import { useClickAway, useWindowScroll } from "@uidotdev/usehooks";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../components/ui/dropdown-menu'
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../components/ui/alert-dialog'
import mergeRefs, { cn, convertToUrl } from '../lib/utils'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import axios from '../lib/axios'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem } from '../components/ui/select'

type LinksProps = {
    onClickLink?: () => void;
}

const Login = () => {
    const {t, i18n} = useTranslation()
    const {auth, setAuth} = useAuth()
    const dispatch = useDispatch()
    return (
        <>
        {!auth.data 
        ? 
        <div className='flex items-center gap-1'>
            <button onClick={()=>{dispatch(setLoginPopup(true))}} className='flex items-center justify-center duration-300 border rounded-full bg-tifo/50 border-white/20 w-9 h-9 hover:bg-tifo/70'>
                <User2 className='w-5 text-white'/>
            </button>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <button className='flex items-center justify-center duration-300 border rounded-full bg-tifo/50 border-white/20 w-9 h-9 hover:bg-tifo/70'>
                        <GlobeIcon className='w-5 text-white'/>
                    </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem onSelect={()=>{ i18n.changeLanguage('nl') }}>Nederlands {i18n.language === 'nl' && <CheckIcon className='w-4 h-4 ml-2'/>}</DropdownMenuItem>
                    <DropdownMenuItem onSelect={()=>{ i18n.changeLanguage('en') }}>English {i18n.language === 'en' && <CheckIcon className='w-4 h-4 ml-2'/>}</DropdownMenuItem>
                    <DropdownMenuItem onSelect={()=>{ i18n.changeLanguage('fr') }}>Français {i18n.language === 'fr' && <CheckIcon className='w-4 h-4 ml-2'/>}</DropdownMenuItem>
                    <DropdownMenuItem onSelect={()=>{ i18n.changeLanguage('de') }}>Deutsch {i18n.language === 'de' && <CheckIcon className='w-4 h-4 ml-2'/>}</DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            
        </div>
        : 
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <div className='relative flex items-center gap-2 duration-300 rounded-full cursor-pointer outline outline-tifo/70 outline-0'>
                        <div className='flex items-center justify-center text-sm font-semibold text-white border rounded-full w-9 h-9 bg-tifo/50 backdrop-blur-sm border-white/20'>{auth.data.m_sFirstName.charAt(0)}</div>
                        {((auth.data.m_oPartner.m_sName==="") || (auth.data.m_oPartner.m_sName!=="" && !auth.data.m_oPartner.m_iPartnerID)) && <div className='absolute w-4 min-w-[1rem] h-4 bg-red-600 top-0 left-0 rounded-full -translate-x-1 -translate-y-1 text-white font-semibold flex justify-center items-center text-xs'>!</div>}
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className='w-full max-w-sm'>
                    <div className='flex items-center gap-2 p-4'>
                        <div className='flex items-center justify-center w-10 h-10 text-lg font-bold rounded-full select-none bg-tifo text-tifo-light'>{auth.data.m_sFirstName.charAt(0)}</div>
                        <div>
                            <DropdownMenuLabel className='pb-0 text-base font-bold'>{auth.data.m_sFirstName} {auth.data.m_sLastName}</DropdownMenuLabel>
                            <div className='px-2 pb-1.5'>
                                <p className='text-sm text-neutral-400'>{auth.data.m_sEmail}</p>
                            </div>
                        </div>
                    </div>
                    

                    <DropdownMenuSeparator/>

                    {!auth.data.m_oDepartment.m_iDepartmentID && 
                    <>
                    <DropdownMenuGroup className='p-2 text-red-600 hover:bg-transparent'>
                        <DropdownMenuItem className='px-4 py-3 font-medium hover:bg-transparent'>
                            <AlertTriangle className='w-4 h-4 mr-2'/>
                            {(auth.data.m_oPartner.m_sName==="") && <span>{t("navigation.no_company")}</span>}
                            {(auth.data.m_oPartner.m_sName!=="" && !auth.data.m_oDepartment.m_iDepartmentID) && <span>{t("navigation.no_department")}</span>}
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator/>
                    </>
                    }

                    <DropdownMenuGroup className='flex flex-col p-2'>
                        <Link to="/profile"><DropdownMenuItem className='px-4 py-3 font-medium'><User className='w-4 h-4 mr-2'/><span>{t("navigation.profile")}</span></DropdownMenuItem></Link>
                        {(auth.data.m_oPartner.m_sName==="") && <Link to="/uitleg/uitnodiging/partner"><DropdownMenuItem className='px-4 py-3 font-medium'><Building2 className='w-4 h-4 mr-2'/><span>{t("navigation.link_company")}</span></DropdownMenuItem></Link>}
                        {(!auth.data.m_oDepartment.m_iDepartmentID) && <Link to="/uitleg/uitnodiging/afdeling"><DropdownMenuItem className='px-4 py-3 font-medium'><Building2 className='w-4 h-4 mr-2'/><span>{t("navigation.link_department")}</span></DropdownMenuItem></Link>}
                    </DropdownMenuGroup>

                    <DropdownMenuSeparator />

                    <div className='p-2'>
                        <Select onValueChange={(v)=>{ i18n.changeLanguage(v.toLowerCase()) }} value={i18n.language.toLowerCase()}>
                            <SelectTrigger className='pl-4 font-medium bg-transparent border-none rounded-md'>
                                <SelectValue placeholder="Selecteer een taal..."/>
                            </SelectTrigger>
                            <SelectContent>
                            <SelectGroup>
                                <SelectItem value="nl">Nederlands</SelectItem>
                                <SelectItem value="en">English</SelectItem>
                                <SelectItem value="fr">Francais</SelectItem>
                                <SelectItem value="de">Deutsch</SelectItem>
                            </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>

                    <DropdownMenuSeparator />

                    <DropdownMenuGroup className='flex flex-col p-2'>
                        <DropdownMenuItem className='px-4 py-3 font-medium' onClick={()=>{localStorage.removeItem('authToken'); setAuth({data: null,status: null})}}><LogOut className="w-4 h-4 mr-2" /><span>{t("navigation.logout")}</span></DropdownMenuItem>
                    </DropdownMenuGroup>
                    
                </DropdownMenuContent>
            </DropdownMenu>
        </>
        }
        </>
    )
}

const Links : React.FC<LinksProps> = ({onClickLink}) => {
    const {auth} = useAuth()    
    const {t} = useTranslation()

    return (
        <div className='flex flex-col justify-center gap-2 lg:bg-tifo/50 lg:backdrop-blur-sm lg:py-2 lg:px-4 lg:h-10 lg:rounded-full lg:items-center lg:gap-4 lg:border border-white/20 lg:flex-row'>
            {auth.data && <li onClick={()=>{onClickLink && onClickLink()}} className='flex items-center py-2 lg:py-0'><a href={`/${auth.data?.m_oPartner.m_sUrl ? `${convertToUrl(auth.data.m_oPartner.m_sUrl)}#events` : ''}`} className='text-base font-medium text-white lg:text-sm whitespace-nowrap'>{t("navigation.events")}</a></li>}
            {auth.data && <li onClick={()=>{onClickLink && onClickLink()}} className='flex items-center py-2 lg:py-0'><Link to="/klassementen" className='text-base font-medium text-white lg:text-sm whitespace-nowrap'>{t("navigation.ranking")}</Link></li>}
            {auth.data && <li onClick={()=>{onClickLink && onClickLink()}} className='flex items-center py-2 lg:py-0'><Link to="/profile#voorspellingen" className='text-base font-medium text-white lg:text-sm whitespace-nowrap'>{t("navigation.predictions")}</Link></li>}
        </div>
    )
}

type NavbarProps = {
    className?: string
}

const Navbar = ({className}:NavbarProps) => {
    const {i18n} = useTranslation()
    const {auth} = useAuth()
    const [{ y }] = useWindowScroll();
    const [show_navigation, setshow_navigation] = useState(false);
    const prevScrollPos = useRef(window.scrollY)
    const [direction, setdirection] = useState<'up' | 'down'>('up');
    const navRef = useClickAway(()=>{if(window.innerWidth < 1024){ setshow_navigation(false) }})

    useEffect(()=>{
        const currentScrollPos = y;
        if(prevScrollPos.current > currentScrollPos){
            //user scrolled up
            setdirection('up')
        }
        else {
            //user scrolled down
            setdirection('down')
        }
        prevScrollPos.current = currentScrollPos;
    }, [y])

    const { data:partner } = useQuery<Partner | null>({
        queryKey: [`partner_${auth.data?.m_oPartner?.m_iPartnerID}`],
        queryFn: async () => {
            try {
                const { data } = await axios.get(`partners/${auth.data?.m_oPartner?.m_iPartnerID}`)
                return data;
            } catch (error) {
                console.log(error);
                return null
            }
        },
        enabled: !!auth.data?.m_oPartner.m_iPartnerID
    })
    

    return (
    <>
    <div className={cn(`${y === 0 ? '' : (((y < 100) || direction === 'up') ? 'bg-tifo py-6 delay-200' : 'bg-tifo/0 h-0 p-0 opacity-0 overflow-hidden -translate-y-full')} fixed duration-500 top-0 z-50 w-full flex justify-center h-[88px]`, className)}>
        <div className='grid items-center w-full max-w-5xl grid-cols-2 gap-0 mx-4 lg:grid-cols-3 xl:gap-4'>       
            <div className='flex items-center gap-4'>
                <button onClick={()=>{setshow_navigation(true)}} className='flex w-9 min-w-[36px] h-9 lg:hidden items-center justify-center rounded-full bg-tifo/50 backdrop-blur-sm border border-white/20'><Grip className='w-5 text-white' strokeWidth={1}/></button>
                <Link to="/">
                    {partner ? <img className='h-9' src={partner.m_sLogo}/> : <img className='h-9' src={logo} alt="Logo"/>}
                </Link>
            </div>

            <div className='items-center justify-center hidden lg:flex'>
                {auth.data && <Links />}
            </div>

            <div className='flex items-center justify-end'>
                <Login />

                {/* {auth.data && <button className='relative flex items-center justify-center w-10 h-10 duration-300 bg-transparent rounded-full hover:bg-tifo/70'>
                    <div className='absolute w-4 min-w-[1rem] h-4 bg-red-600 top-0 right-0 rounded-full text-white font-semibold flex justify-center items-center text-xs'>1</div>
                    <Bell className='w-5 text-white'/>
                </button>} */}
            </div>
        </div>
    </div>
    
    <div ref={mergeRefs(navRef)} className={`fixed h-[100dvh] ${show_navigation ? 'w-10/12' : 'w-0'} z-50 bg-tifo/90 backdrop-blur-lg top-0 left-0 overflow-hidden duration-500 flex flex-col`}>
        <div className='h-[80px] flex items-center mx-4'>
            <button onClick={()=>{setshow_navigation(false)}} className='translate-y-1 flex w-9 h-9 min-w-[36px] lg:hidden items-center justify-center rounded-full bg-white/10 backdrop-blur-sm border border-white/20'><X className='w-5 text-white' strokeWidth={1.5}/></button>
        </div>
        <div className='flex flex-col justify-between h-full m-4'>
            <Links onClickLink={()=>{setshow_navigation(false)}}/>
            
            <Select onValueChange={(v)=>{ i18n.changeLanguage(v.toLowerCase()) }} value={i18n.language.toLowerCase()}>
                <SelectTrigger className='text-white bg-transparent rounded-md w-fit border-tifo-light'>
                  <SelectValue placeholder="Selecteer een taal..."/>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="nl">Nederlands</SelectItem>
                    <SelectItem value="en">English</SelectItem>
                    <SelectItem value="fr">Francais</SelectItem>
                    <SelectItem value="de">Deutsch</SelectItem>
                  </SelectGroup>
                </SelectContent>
            </Select>
        </div>
    </div>
    
    </>
  )
}

export default Navbar